<template>
  <el-card id="callRanking">
    <header>
      <h1>通话时长排名</h1>
      <div class="search">
        <div class="right">
          <el-button @click="getList('daytableAll')" type="primary" size="small"
            >今日</el-button
          >
          <el-button
            @click="getList('weektableAll')"
            type="primary"
            size="small"
            >本周</el-button
          >
          <el-button
            @click="getList('monthtableAll')"
            type="primary"
            size="small"
            >当月</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <div id="chartColumn" style="height: 400px; width: 100%"></div>
    </main>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { tableTimeAll } from "@/api/table";
export default {
  data() {
    return {
      chartColumn: null,
      titleArr: [],
      valueArr: [],
      roleName: "", // 判断是公司还是员工
      parentId: "", // 公司id
      id: "", // 个人id
    };
  },
  async created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.id = localStorage.getItem("id");
    let res = await this.getList();
    let res1 = await this.drawColumnChart();
  },
  methods: {
    async getList(url = "daytableAll") {
      let userId = "";
      if (this.roleName == "企业") {
        userId = this.id;
      } else {
        userId = this.parentId;
      }
      let params = {
        userId,
      };
      let res = await tableTimeAll(url, params);
      console.log(res.data.data);
      let data = res.data.data;
      this.titleArr = [];
      this.valueArr = [];
      let newArr = data.map((v) => {
        return { title: v.name, value: v.seconds };
      });
      newArr.sort(function (a, b) {
        return b.value - a.value;
      });
      // console.log("新数组", newArr);
      newArr.forEach((v) => {
        this.titleArr.push(v.title);
        this.valueArr.push(v.value);
      });
      this.$nextTick(() => {
        this.drawColumnChart();
      });
    },
    drawColumnChart() {
      console.log("标题", this.titleArr);
      console.log("值", this.valueArr);
      this.chartColumn = echarts.init(document.getElementById("chartColumn"));
      this.chartColumn.setOption({
        title: { text: "通话时长排名" },
        tooltip: {},
        xAxis: {
          //   interval: 0, //去除间隙  刻度间隔为0
          data: this.titleArr,
          //   data: [12, 232, 123, 123, 12, 3, 123, 1, 23, 123, 1, 231],
        },
        yAxis: {
          // data: this.valueArr,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            // debugger;
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 4; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于3,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            name: "通话时长",
            type: "bar",
            data: this.valueArr,
          },
        ],
      });
    },
  },
  updated: async function () {
    let res = await this.drawColumnChart();
  },
};
</script>

<style lang="less" scoped>
#callRanking {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      margin-top: 20px;
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>